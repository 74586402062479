import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../assets/styles/Login.css";
import { Image } from "@mantine/core";

export default function Header({ about }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  console.log(about);

  const activeLinkStyle = {
    fontWeight: 'bold',
    color: '#fff',
  };

  return (
    <div
      className="header"
      style={{
        background: about ? "linear-gradient(135deg, #2f193d, #0d0909)" : "",
        width: "100%",
        height: "80px",
        right: about ? "1px" : ""
      }}
    >
      <div className="logo">
        <Image
          style={{
            width: "190px",
            height: "75px",
            position: "relative",
            right: "30px",
            bottom: "10px"
          }}
          src={`${process.env.PUBLIC_URL}/file.png`}
        />
      </div>
      <div
        className={`burger-menu ${isMenuOpen ? "open" : ""}`}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      ></div>
      <nav className={`nav-menu ${isMenuOpen ? "active" : ""}`} style={{ background: about && isMenuOpen ? "linear-gradient(135deg, #2f193d, #0d0909)" : "" }}>
        <div className="nav-text" style={{ background: "" }}>

          <NavLink to="/" style={({ isActive }) => (isActive ? activeLinkStyle : {})}
          >
            الصفحة الرئيسية
          </NavLink>
          <NavLink to="/chat" style={({ isActive }) => (isActive ? activeLinkStyle : {})}>
            الدردشة
          </NavLink>
          <NavLink to="/about" style={({ isActive }) => (isActive ? activeLinkStyle : {})}

          >
            عن المنصة
          </NavLink>
        </div>
      </nav>
    </div>
  );
}
